<template>
  <div class="space-y-4">
    <SettingsCard />
  </div>
</template>

<script>
import SettingsCard from '@/ui/components/Merchant/Shops/SettingsCard.vue';

export default {
  components: {
    SettingsCard,
  },
};
</script>
