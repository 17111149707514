<template>
  <CCard spacing>
    <CTypo tstyle="title3">
      {{ $t('settings') }}
    </CTypo>
    <CForm 
      v-if="!loading" 
      class="mt-4" 
      @submit="onSubmit"
      :loading="submitLoading"
      :error-fields-response="errorFieldsResponse"
    >
      <CTextField
        v-model="form.minDaysBetweenOrderAndDelivery"
        :label="$t('shops.minDaysBetweenOrderAndDelivery')"
        type="number"
        step="1"
        min="1"
        max="100"
        required
      />
      <CSelect
        :label="$t('shops.deliveryTime')"
        @input="handleDeliveryTimeSelection"
        :value="form.fromDeliveryTime"
        v-autofocus
      >
        <option
          v-for="generalTimeInput in generalTimeInputs"
          :key="generalTimeInput"
          :value="generalTimeInput"
        >
          {{ $t(generalTimeInput) }}
        </option>

        <option
          v-for="timeInput in timeInputs"
          :key="timeInput"
          :value="timeInput"
        >
          {{ $t('from') }} {{ timeInput }}
        </option>
      </CSelect>
      <CSelect
        :label="$t('shops.pickupTime')"
        @input="handlePickupTimeSelection"
        :value="form.fromPickupTime"
        v-autofocus
      >
        <option
          v-for="generalTimeInput in generalTimeInputs"
          :key="generalTimeInput"
          :value="generalTimeInput"
        >
          {{ $t(generalTimeInput) }}
        </option>

        <option
          v-for="timeInput in timeInputs"
          :key="timeInput"
          :value="timeInput"
        >
          {{ $t('from') }} {{ timeInput }}
        </option>
      </CSelect>
      <CCheckboxList v-model="this.deliveryDaysSelection" required>
        {{ $t('shops.deliveryDays') }}
      </CCheckboxList>
    </CForm>
  </CCard>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { UPDATE_SHOP } from '@/store/actionTypes';
import { SET_SUCCESS_NOTIFICATION } from '@/store/mutationsTypes';

export default {
  data: () => ({
    form: {
      minDaysBetweenOrderAndDelivery: null,
      fromDeliveryTime: null,
      fromPickupTime: null,
    },
    generalTimeInputs: ['allDay', 'morning', 'inTheAfternoon'],
    timeInputs: Array(24)
      .fill()
      .map((_, i) => `${i < 10 ? '0' : ''}${i}:00`),
    deliveryDaysSelection: [],
    errorFieldsResponse: null,
  }),

  computed: {
    loading() {
      return this.$store.state.shop.loading;
    },
    submitLoading() {
      return this.$store.state.shop.submitLoading;
    },
    shopData() {
      return this.$store.state.shop.shop;
    },
  },

  watch: {
    shopData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.deliveryDaysSelection = [
            { value: 'monday', checked: false, translation: this.$t('days.monday') },
            { value: 'tuesday', checked: false, translation: this.$t('days.tuesday') },
            { value: 'wednesday', checked: false, translation: this.$t('days.wednesday') },
            { value: 'thursday', checked: false, translation: this.$t('days.thursday') },
            { value: 'friday', checked: false, translation: this.$t('days.friday') },
            { value: 'saturday', checked: false, translation: this.$t('days.saturday') },
            { value: 'sunday', checked: false, translation: this.$t('days.sunday') },
          ];

          this.form.minDaysBetweenOrderAndDelivery = newVal.minDaysBetweenOrderAndDelivery;
          this.form.fromDeliveryTime = newVal.fromDeliveryTime;
          this.form.fromPickupTime = newVal.fromPickupTime;

          newVal.deliveryDays.forEach((deliverDay) => {
            const i = this.deliveryDaysSelection.findIndex(
              (deliverDaySelect) => deliverDaySelect.value === deliverDay.name,
            );
            this.deliveryDaysSelection[i].checked = true;
          });
        }
      },
    },
    form: {
      handler() {
        this.errorFieldsResponse = null;
      },
      deep: true,
    },
    deliveryDaysSelection: {
      handler() {
        this.errorFieldsResponse = null;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([UPDATE_SHOP]),
    ...mapMutations([SET_SUCCESS_NOTIFICATION]),
    handleDeliveryTimeSelection(val) {
      this.form.fromDeliveryTime = val;
    },
    handlePickupTimeSelection(val) {
      this.form.fromPickupTime = val;
    },
    async onSubmit() {
      const payload = {
        minDaysBetweenOrderAndDelivery: this.form.minDaysBetweenOrderAndDelivery,
        fromDeliveryTime: this.form.fromDeliveryTime,
        fromPickupTime: this.form.fromPickupTime,
        deliveryDays: [],
      };
      this.deliveryDaysSelection.forEach((deliverDaySelect) => {
        if (deliverDaySelect.checked) {
          payload.deliveryDays.push(deliverDaySelect.value);
        }
      });
      try {
        await this.updateShop(payload);
        this.setSuccessNotification({ content: null, show: true });
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.errorFieldsResponse = e.response;
        } else {
          throw e;
        }
      }
    },
  },
};
</script>
